import { FC } from 'react'

const MoonIcon: FC = () => (
  <svg
    viewBox='0 0 24 24'
    width='24'
    height='24'
    style={{ marginBottom: '0.19rem', cursor: 'pointer', userSelect: 'none' }}
    aria-label='Moon Icon'
  >
    <path
      style={{
        fill: 'var(--text-primary)',
      }}
      d='M9.37,5.51C9.19,6.15,9.1,6.82,9.1,7.5c0,4.08,3.32,7.4,7.4,7.4c0.68,0,1.35-0.09,1.99-0.27C17.45,17.19,14.93,19,12,19 c-3.86,0-7-3.14-7-7C5,9.07,6.81,6.55,9.37,5.51z M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36 c-0.98,1.37-2.58,2.26-4.4,2.26c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z'
    ></path>
  </svg>
)

export default MoonIcon
