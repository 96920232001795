import { languageText } from 'src/shared/types'

export const subtitle: languageText = {
  en: 'Administrate you.',
  jp: '君を管理する。',
}

export const underConstruction: languageText = {
  en: 'Under Construction (・-・)7',
  jp: '建設中 (・-・)7',
}

export const loadingStyles = {
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}
